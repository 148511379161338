<template>
  
  <template  v-if="!_state.isLogin">
    <div class="title">登录</div>
    <a-form
      :model="formState"
      name="basic"
      class="login-form"
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 16 }"
      autocomplete="off"
      @finish="onFinish"
    >
      <a-form-item
        label="账号"
        name="username"
        :rules="[{ required: true, message: '请输入账号！' }]"
      >
        <a-input v-model:value="formState.username" />
      </a-form-item>

      <a-form-item
        label="密码"
        name="password"
        :rules="[{ required: true, message: '请输入密码！' }]"
      >
        <a-input-password v-model:value="formState.password" />
      </a-form-item>

    

      <a-form-item :wrapper-col="{ offset: 2, span: 24 }">
        <a-button type="primary" html-type="submit" style="width: 100%;">登录</a-button>
      </a-form-item>
    </a-form>
  </template>

  <router-view v-if="_state.isLogin"></router-view>
</template>

<script lang="ts" setup>
import { reactive, onMounted } from 'vue';
// import Login from './components/Login.vue';
import { useRouter, useRoute } from "vue-router";

import DigitalHuman from './components/DigitalHuman.vue';

const _state  = reactive({
  token: '',
  
  isLogin: false,
})

const formState = reactive({
    username: 'admin',
    password: '',
});

const router = useRouter();
const route = useRoute();

const onFinish = (values: any) => {

  if (values.username === 'admin' && values.password === '000000') {
    (localStorage as any).setItem("token", new Date().getTime());
    (localStorage as any).setItem("hid", '000000');
    router.push("/digital-human");
    _state.isLogin = true;
  }

  if (values.username === 'admin' && values.password === '666666') {
    (localStorage as any).setItem("token", new Date().getTime());
    (localStorage as any).setItem("hid", '666666');
    router.push("/digital-human/666");
    _state.isLogin = true;
  }

  if (values.username === 'admin' && values.password === '888888') {
    (localStorage as any).setItem("token", new Date().getTime());
    (localStorage as any).setItem("hid", '888888');
    router.push("/digital-human/888");
    _state.isLogin = true;
  }
};

onMounted(() => {
  const token = (localStorage as any).getItem("token");
  const hid = (localStorage as any).getItem("hid");

  if (token) {
    const nextDay: any = new Date(parseInt(token) + (24 * 60 * 60 * 1000));
    const curDay: any = new Date().getTime();
    if (curDay > nextDay) {
      _state.isLogin = false;
    } else {
      if (hid === '666666') {
        router.push("/digital-human/666");
      } else if (hid === '888888') {
        router.push("/digital-human/888");
      } else {
        router.push("/digital-human");
      }

      _state.isLogin = true;
    }
  } else {
    _state.isLogin = false;
  }

  
})

</script>

<style>
.login-form {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.title {
  font-size: 22px;
  width: 300px;
  text-align: center;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  padding: 120px 0 40px 0;
}
</style>
