import { createRouter, createWebHashHistory } from "vue-router";

import DigitalHuman from "./components/DigitalHuman.vue";
import DigitalHumanB from "./components/DigitalHumanB.vue";
import DigitalHumanC from "./components/DigitalHumanC.vue";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
      {
        path: "/",
        // component: Layout,
        redirect: "/digital-human",
        children: [
          { path: "/digital-human", component: DigitalHuman },
          { path: "/digital-human/666", component: DigitalHumanB },
          { path: "/digital-human/888", component: DigitalHumanC },
        ],
      }
    ],
  });

  router.afterEach((to, from) => {
    
  });


  export default router;