<template>
  <img class="d_logo" src="../assets/images/digital_human_002_logo.png" />
  <div class="main-bg">
    <div class="main">
      <div class="left">
        <div class="box"></div>
        <!-- <div class="mark"></div> -->
        <!-- <div class="mode">
          <div class="imgCon current">
            <img class="img" src="../../public/work.png" />
            <div>工作模式</div>
          </div>
          <div class="imgCon">
            <img class="img" src="../../public/funny.png" />
            <div>娱乐模式</div>
          </div>
          <div class="imgCon">
            <img class="img" src="../../public/test.png" />
            <div>测试模式</div>
          </div>
        </div> -->
      </div>
      <div class="right">
        <div class="inputCon">
          <div class="self">
            Welcome to your virtual English speaking practice with Berlitz
            Speaking Companion!
          </div>
          <div class="input">
            <div class="out">
              <div v-show="isShow" class="recording-animation"></div>
              <a-input
                :style="{
                  width: '688px',
                  height: '47px',
                  background: 'rgba(215, 215, 207, .5)',
                  border: 'none',
                  borderRadius: '28px',
                  marginTop: '10px',
                  marginLeft: '10px',
                  paddingLeft: '32px',
                  fontSize: '20px',
                }"
                v-model:value="value"
                placeholder="Please enter..."
                allowClear
                @pressEnter="onSearch"
              >
                <template #suffix>
                  <div class="send-bw" @click="getAnswer(value)">
                    <ArrowRightOutlined />
                  </div>
                </template>
              </a-input>
            </div>
            <div class="audio">
              <a-tooltip v-if="!flag" title="Turn off microphone">
                <AudioOutlined v-if="!flag" class="audio-icon" @click="stopAudio" />
              </a-tooltip>
              <a-tooltip v-if="flag" title="Turn on microphone">
                <AudioMutedOutlined v-if="flag" class="audio-icon" @click="startAudio" />
              </a-tooltip>

                <a-tooltip title="Break talking">
                  <PauseCircleOutlined style="margin-left: 12px;" class="audio-icon" @click="breakTalking" />
                </a-tooltip>
            </div>
          </div>

          <div class="btnCon">
            <a-button
              v-for="item in _state.menusData"
              class="btn"
              type="primary"
              shape="round"
              :size="size"
              ghost
              @click="getAnswer(item)"
              >{{ item }} <ArrowRightOutlined
            /></a-button>
            <!-- <a-button
              class="btn"
              type="primary"
              shape="round"
              :size="size"
              ghost
              @click="getAnswer('Specials today?')"
              >Specials today?<ArrowRightOutlined
            /></a-button>
            <a-button
              class="btn"
              type="primary"
              shape="round"
              :size="size"
              ghost
              @click="getAnswer('Busy morning?')"
              >Busy morning?<ArrowRightOutlined
            /></a-button>
            <a-button
              class="btn"
              type="primary"
              shape="round"
              :size="size"
              ghost
              @click="getAnswer('Cash only?')"
              >Cash only?<ArrowRightOutlined
            /></a-button> -->
            <RedoOutlined class="refresh-icon" @click="_refreshMenu" />
          </div>
          <div class="areaCon" id="container" v-html="areaCon"></div>

         
        </div>
      </div>
    </div>
  </div>

  <!-- <div style="position: fixed; left: 10px; top: 10px; cursor: pointer; color:#fff; font-size: 22px;z-index: 9999;" @click="_testClose">关闭</div>
  <div style="position: fixed; left: 80px; top: 10px; cursor: pointer; color:#fff; font-size: 22px;z-index: 9999;" @click="_testStart">开始</div> -->
</template>
<script lang="ts" setup>
import { ref, onMounted, onUnmounted, reactive } from 'vue';
import {
  SendOutlined,
  AudioOutlined,
  ArrowRightOutlined,
  AudioFilled,
  TableOutlined,
  PauseCircleOutlined,
  RedoOutlined,
  AudioMutedOutlined,
} from '@ant-design/icons-vue';
import { replaceChineseCharsAndPunctuation } from "../tools.ts"

const tempHtml = '<p>In this session, you will complete a role-play scenario where you will practice ordering breakfast at a New York cafe.<br> When it’s your turn to say something, click on the “Record” button. Once you’re done speaking, click “Stop.” Then, wait a few seconds to hear the response from me, your virtual Berlitz Speaking Companion.<br> Now, if you are ready, say hi to me and we will start the role play.</p><p>Breakfast Menu:<br> Bagel with Cream Cheese - $4.00 Pancakes - $5.50<br> Scrambled Eggs - $6.00<br> French toast - $4.00<br> Oatmeal- $3.50<br> Fruit salad - $4.50 Coffee - $2.50<br> Tea - $2.00<br> Orange Juice - $3.00 Smoothies - $5.00</p>'

const value = ref<string>('')
let signature = ref<string>('')
let areaCon = ref<string>(tempHtml)
let flag = ref<boolean>(true)
let isShow = ref<boolean>(false)
const size = ref<any>('large')
let Client: any = ref<any>(null)
let asr: any = ref<any>(null)

const _state = reactive({
  talking: false,

  menuIdx: 0,
  menusData: [] as any,
});

const MENUS_ARRAY = [
  ["Menu please?", "Specials today?", "Busy morning?", "Seat myself?"],
  ["Water first?", "Restroom where?", "Cash only?", "How long wait?"],
  ["Gluten-free options?", "Breakfast all day?", "Kids menu?", "To-go possible?"],
  ["Busy later?", "Fresh coffee?", "Syrup flavors?", "Eggs how?"],
  ["Substitutions okay?", "Biggest portion?", "Early bird special?", "Recommendation?"]
]

let resultText: any = '';
let allContent = '';

const SUBSCRIPTION_KEY = "5c6c0f6414024deb8ab3d7f8952688d3";
const PROJECT_ID = "d119beee-585e-11ef-a42a-398643d9d5ad";
let Signature: any = '';

const _testClose = () => {
  Client.endRTC();
}

const _testStart = () => {
  Client.startRTC();
}

onMounted( async () => {
  (localStorage as any).removeItem("xxxxxx");
  _state.menusData = MENUS_ARRAY[0];
  _asrsdkAuth();

  Signature = await  _getSignature();
  _initRTC();

  window.addEventListener('beforeunload', (event) => {
    // 这里可以执行你想要的任何操作，比如提示用户是否真的想要离开页面
    // 设置event.returnValue以显示提示信息
    _leavePage();
    event.returnValue = '你确定要离开这个页面吗？';
  });
})

onUnmounted(() => {

  _leavePage();
  
})

const _leavePage = () => {
  try {
    Client.breakTalking();
    Client.endRTC();
    Client.destroy();
    asr.stop();
  } catch (error) {
    
  }
}

const _getSignature = () => {
  return new Promise((reso, rej) => {
    fetch('https://interactive-virtualhuman.xiaoice.com/openapi/signature/gen', {
      headers: {
        'Content-Type': 'application/json',
        //注意 这里只是为了演示快速启动，客户使用时不要暴露该值  建议后端请求signature
        'subscription-key': SUBSCRIPTION_KEY,
      },
    }).then((res) => res.json()).then((res: any) => {
      // console.log(res)
     const signature = res?.data;
     reso(signature);
    }).catch(() => {
      rej("");
    })
  });
}

const _asrsdkAuth = () => {
  window.AsrSDK.authUseMicrophone().then((res: any) => {
    console.log('==>授权成功', res)
  }).catch((err: any) => {
    console.log('==>授权失败', err)
  })
}

const _initRTC = () => {
  Client = new window.RTCInteraction({
      mountClass: 'box',
      signature: Signature,
      projectId: PROJECT_ID,
      onError(errorCode: any, errorMessage: any) {
        // console.log(errorCode, errorMessage)
      },
      onInited(res: any) {
        // console.log('inited', res)
        Client.startRTC();

        _initARS();
      },
      onPlayStream(v3Orv4: any) {
        // console.log('play')
        // console.log('v3Orv4', v3Orv4)
      },
      onStopStream() {
        // console.log('stop')
      },
      onTalkStart(talkRes: any) {
        // console.log(talkRes)
        _state.talking = true;
        let newText = talkRes.content
        
        let _newText = `${areaCon.value}${newText}`;

        allContent = `${allContent}${newText}<br/><br/>`

        areaCon.value = newText;
      },
      onTalkEnd(talkRes: any) {
        // console.log(talkRes)

        _state.talking = false;
      },
  })

}

const _initARS = () => {
  asr = new window.AsrSDK({
    // 开始识别
    onRecognitionStart: (res: any) => {
      // console.log('开始识别', res)
    },
    // ⼀句话开始
    onSentenceBegin: (res: any) => {
      // console.log('⼀句话开始', res)
      // isCanStop = true
      // $('#end').show()
      // $('#recognizing').show()
      // $('#connecting').hide()
    },
    // 识别变化时
    onRecognitionResultChange: (res: any) => {
      // console.log('识别变化时', res)
      const currentText = `${resultText}${res.result.voice_text_str}`
      // areaDom.text(currentText)
      const _outputText = replaceChineseCharsAndPunctuation(currentText);
      value.value = _outputText;

    },
    // ⼀句话结束
    onSentenceEnd: (res: any) => {
      // console.log('⼀句话结束', res)
      const _txtStr = res.result.voice_text_str;
      // resultText += _txtStr;
      // areaDom.text(resultText)
      const _outputText = replaceChineseCharsAndPunctuation(_txtStr);
      value.value = _outputText;
      Client.ask(_txtStr);
    },
    // 识别错误
    onError: (res: any) => {
      // console.log('识别失败', res)
      // $('#end').hide()
      // $('#recognizing').hide()
      // $('#start').show()
      // $('#connecting').hide()
    },
    projectId: PROJECT_ID,
  });

  asr.setIsDebug(false);
  // console.log(">>>>>>>>>>>>", asr)
  // asr.start(signature);
}

const startAudio = () => {
  isShow.value = true;
  flag.value = false;
  _state.talking = true;
  asr.start(Signature);
}

const stopAudio = () => {
  isShow.value = false;
  flag.value = true;
  value.value = "";

  _state.talking = false;
  asr.stop();
}

const breakTalking = () => {
  Client.breakTalking();
}

const onSearch = (searchValue: string) => {
  flag.value = true
 
  if (value.value) {
    allContent = '';
    Client.ask(value.value)
  }
}

const getAnswer = (str: any) => {
  value.value = str;
  flag.value = true;
  
  if (str) {
    allContent = '';
    Client.ask(str)
  }
}

const _refreshMenu = () => {
  let idx = _state.menuIdx + 1;
  if (idx > MENUS_ARRAY.length - 1 ) {
    _state.menuIdx = 0;
    _state.menusData = MENUS_ARRAY[0]
  } else {
    _state.menuIdx = idx;
    _state.menusData = MENUS_ARRAY[idx]
  }
}

const _onSearch = (searchValue: string) => {
  flag.value = true
  Client.startRTC()
  asr?.stop()
  if (value.value) {
    // Client.talk(value.value, false)
    allContent = '';
    Client.ask(value.value)
  }
}
const _getAnswer = (str: any) => {
  value.value = str
  flag.value = true
  asr?.stop()
  Client.startRTC()
  const buttons = document.querySelectorAll('.btn')
  buttons.forEach(function (button) {
    button.addEventListener('click', function () {
      button.classList.add('button-animation')

      setTimeout(() => {
        button.classList.remove('button-animation')
      }, 300)
    })
  })
  if (str) {
    allContent = '';
    Client.ask(str)
  }
}

const _startAudio = () => {
  flag.value = !flag.value
  asr?.stop()
  value.value = ''
 
  if (!flag.value) {
    if (signature) {
      asr?.start(signature)
      isShow.value = true
    }
  } else {
    Client.breakTalking()
    isShow.value = false
  }
}


const getSignature = () => {
  window.AsrSDK.authUseMicrophone()
    .then((res: any) => {
      console.log('==>授权成功', res)
    })
    .catch((err: any) => {
      console.log('==>授权失败', err)
    })
  // 通过subscription-key获取token
  fetch('https://interactive-virtualhuman.xiaoice.com/openapi/signature/gen', {
    headers: {
      'Content-Type': 'application/json',
      //注意 这里只是为了演示快速启动，客户使用时不要暴露该值  建议后端请求signature
      'subscription-key': 'c99f95a5e4934110bed6b57cc67bed8a',
    },
  }).then((res) => {
    // 通过project启动拉流程序
    res.json().then((res) => {
      signature = res?.data
      /**
       * 实际使用时请提前初始化asr，提前获取麦克风权限，成功后再初始化客户端
       */

      // 初始化客户端
      Client = new window.RTCInteraction({
        mountClass: 'box',
        // includeUI: true,
        signature: signature,
        // projectId: '58e73516-5570-11ef-908a-65ce10f0c96a',
        // projectId: '3743c19f-5570-11ef-a99f-8970573075eb',
        // projectId: '42e958ef-5578-11ef-908a-61226a13f6bc',
        projectId: '42e958ef-5578-11ef-908a-61226a13f6bc',
        mode: 0,
        onError(errorCode: any, errorMessage: any) {
          // console.log(errorCode, errorMessage)
        },
        onInited() {
          // console.log('inited')
          Client.startRTC()
          /**
           * 建议实际使用时请在RTCInteraction初始化之前获取麦克风权限
           */

          asr = new window.AsrSDK({
            // 一句话结束
            onSentenceEnd: (res: any) => {
              allContent = '';
              Client.ask(res?.result?.voice_text_str)
              value.value = res?.result?.voice_text_str
              // console.log('一句话结束', res)
              isShow.value = false
              setTimeout(() => {
                value.value = ''
              }, 200)
            },
          })
          // const element = document.querySelector('.mobile-point') as HTMLElement
          // element.addEventListener('click', () => {
          //   Client.startRTC()
          // })
        },
        onPlayStream() {
          asr.start(signature)
        },
        onStopStream() {
          // console.log('stop')
          // console.log('stop')
        },
        onTalkStart(talkRes: any) {
          console.log("xxxx ",talkRes)
          let newText = talkRes.content
          // const regex =
          //   /(一是|二是|三是|四是|五是|六是|七是|八是|九是|十是|通过)/g
          // if (regex.test(talkRes.content)) {
          //   newText = newText.replace(regex, '<br/><br/>$1')
          // }
          let _newText = `${areaCon.value}${newText}`;

          allContent = `${allContent}${newText}<br/><br/>`

          areaCon.value = allContent;
          asr?.stop()
        },
        onTalkEnd(talkRes: any) {
          asr?.stop()
          if (!flag.value) {
            asr.start(signature)
            isShow.value = true
          }
        },
      })
    })
  })
}
</script>

<style>
@import url('./digital.less');
</style>

<style scoped lang="less" src="./digitalHuman.less">
/* @import url("./digitalHuman.less"); */
</style>
